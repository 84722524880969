import { Col, Row } from '@/components/Grid';
import QuestionItem from '@/components/QuestionItem';
import { EMetricType, EQuestionItemType, IQuestionItem, IQuestionOptionStyle, IQuestionResItem, IQuestionResStar, IQuestionSurveyModel } from '@/interface/survey';
import { useRef } from '@/utils/composition-helper';
import { HtmlHelper } from '@/utils/html-helper';
import { isNumber } from '@/utils/number-ext';
import { computed, defineComponent, PropType, toRefs, watch } from '@vue/composition-api';
import _ from 'lodash';
import { MessagePlugin } from 'tdesign-vue';
import { VNode } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { getDisplayTreeNodesByQuestions, getFlattenTreeNode, getQuestionResItems, IDisplayTreeNode, verifyResQuestions } from '../QuestionItem/util';
import './index.less';

/**
 * 问卷预览器
 */
export default defineComponent({
  name: 'QuestionPreviewer',
  props: {
    questions: {
      type: Array as PropType<IQuestionItem[]>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { questions: metaQuestions } = toRefs(props);
    // 已选择的题目（map）
    const [answerMaps, setAnswerMaps] = useRef<Record<string, IQuestionResItem>>({});
    const [displayTreeNodes, setDisplayTreeNodes] = useRef<IDisplayTreeNode[]>([
      { id: '1', indexPath: [0], children: [] },
    ]);
    const title = computed(() => {
      const title = route.query.title ?? '';
      if (_.isString(title) && title.length > 0) {
        return `问卷预览-${title}`;
      }
      return '问卷预览';
    });
    watch(() => title.value, (newVal) => {
      HtmlHelper.setTitle(newVal);
    }, { immediate: true });
    const legacy = computed(() => {
      const model: IQuestionSurveyModel = {
        title: title.value,
        index_group_id: '',
        create_time: '',
        last_modified_by: '',
        update_time: '',
        smart_mode: true,
        metric: EMetricType.CSAT,
        pages: [
          {
            id: '1',
            questions: metaQuestions.value,
          }
        ],
      };
      return model;
    });
    // 如果路由中存在option_id参数，则获取对应需要默认勾选第一道量表题，并展示量表题相关displays
    const defaultDisplayTreeNodes = computed(() => {
      const questions = metaQuestions.value ?? [];
      const firstQuestion = questions?.[0];
      const nodes = getDisplayTreeNodesByQuestions(questions);
      const queryOptionId: string | undefined = route.query.option_id as string;
      const nodeIndex = nodes.findIndex(c => c.id === firstQuestion.id && c.children.length === 0);
      const displayNode = nodes[nodeIndex];
      // 如果第一道题是量表题，进行匹配url参数option_id
      if (firstQuestion?.type === EQuestionItemType.Star && isNumber(queryOptionId) && displayNode) {
        const option = firstQuestion.options.find(c => c.id === route.query.option_id);
        const res: IQuestionResStar = {
          type: EQuestionItemType.Star,
          model: _.cloneDeep(firstQuestion),
          value: option ? [_.cloneDeep(option)] : [],
        };
        nodes[nodeIndex].children = option?.displays.map((c, index) => {
          const model: IDisplayTreeNode = {
            id: c,
            indexPath: [...nodes[nodeIndex].indexPath, index],
            children: [],
          };
          return model;
        }) ?? [];
        return {
          displayNodes: nodes,
          questionRes: res,
        };
      }
      return {
        displayNodes: nodes,
        questionRes: undefined,
      };;
    });
    // 如果路由参数option_id匹配了第一道量表题并有默认参数
    watch(defaultDisplayTreeNodes, (newVal, oldVal) => {
      if (newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        const { questionRes, displayNodes } = newVal;
        setDisplayTreeNodes(displayNodes);
        const displayNode = displayNodes?.[0];
        if (questionRes && displayNode) {
          // setAnswerMaps下方的watch metaQuestion初始化时会覆盖，所以这里使用setTimeout宏任务进行延迟设置
          setTimeout(() => {
            const newMaps = _.cloneDeep(answerMaps.value);
            newMaps[displayNode.id] = questionRes;
            setAnswerMaps(newMaps);
          }, 0);
        }
      }
    }, { immediate: true, deep: true });
    // 显示的题目，默认显示量表题（一维打平）
    const flatDisplayTreeNodes = computed(() => getFlattenTreeNode(displayTreeNodes.value));
    // 根据一维打平结果，最终确定需要提交的问题
    const displays = computed(() => {
      const ids = flatDisplayTreeNodes.value.map(c => Number(c.id));
      const arr = Array.from(new Set(ids)).map(c => c.toString()).filter(c => c !== '-1');
      // 根据问题顺序处理显示问题顺序
      const result = metaQuestions.value.reduce((res: string[], question)=> {
        const index = arr.findIndex(c=> c === question.id);
        if (index > -1) {
          return [...res, question.id];
        }
        return res;
      }, []);
      console.log('displays old:', arr);
      console.log('displays new:', result);
      return result;
    });
    /**
     * 递归获取树形结构列表
     * @param displayNode
     * @returns
     */
    function getDisplayTreeNodes(displayNode: IDisplayTreeNode): IDisplayTreeNode[] {
      const { id, indexPath } = displayNode;
      const info = answerMaps.value[id];
      // 是否需要显示下题问题
      const needDisplayType = info.type !== EQuestionItemType.Text
        && info.type !== EQuestionItemType.SensitiveInput
        && info.type !== EQuestionItemType.Matrix;
      if (info && needDisplayType) {
        const { type, value, model } = info;
        // 永远只显示结果的第一个匹配的displays，旧逻辑
        let children = value?.[0]?.displays?.map((k, index) => {
          const model: IDisplayTreeNode = {
            id: k,
            indexPath: [...indexPath, index],
            children: [],
          };
          return model;
        });
        if (type === EQuestionItemType.Option) {
          const { style } = model;
          // eslint-disable-next-line no-irregular-whitespace
          const styleObj: IQuestionOptionStyle = style ? JSON.parse(style.replace(/: "/g, ':"')) : {
            line_items: '3',
          };
          const { multiple } = styleObj;
          // 如果有multiple属性，则表示不互斥（第二道题假设为多选，有选项2A, 2B）
          // 当勾选2A展示3A和3B
          // 当勾选2B展示3C和3D
          // 勾选2A和2B的同时展示[2A, 2B, 3A, 3B, 3C, 3D]
          if (multiple) {
            children = value.reduce((res: IDisplayTreeNode[], item) => {
              const arr = item?.displays?.map((k, index) => {
                const currIndex = res.length + index;
                const model: IDisplayTreeNode = {
                  id: k,
                  indexPath: [...indexPath, currIndex],
                  children: [],
                };
                return model;
              }) ?? [];
              return [...res, ...arr];
            }, []);
          }
        }
        displayNode.children = children?.reduce((res: IDisplayTreeNode[], child) => {
          return [...res, ...getDisplayTreeNodes(child)];
        }, []) ?? [];
        return [displayNode];
      }
      return [displayNode];
    }
    const displayQuestionEles = computed(() => {
      const eles = displays.value.reduce((res: VNode[], display, index) => {
        const displayNode = flatDisplayTreeNodes.value.find(c => c.id === display);
        const question = answerMaps.value[display];
        if (question && displayNode && legacy.value) {
          const { indexPath } = displayNode;
          return [
            ...res,
            <QuestionItem
              index={index}
              surveyModel={legacy.value}
              answerMaps={answerMaps.value}
              value={question}
              onChange={(val) => {
                const newMaps = _.cloneDeep(answerMaps.value);
                newMaps[display] = val;
                setAnswerMaps(newMaps);
                const nodes = getDisplayTreeNodes(displayNode);
                const newArr = _.cloneDeep(displayTreeNodes.value);
                const path = indexPath.join(',children,').split(',');
                const newModel = _.cloneDeep(displayNode);
                newModel.children = nodes[0].children;
                // 替换所有数组中的元素
                _.set(newArr, path, newModel);
                setDisplayTreeNodes(newArr);
              }}
            />,
          ];
        }
        return res;
      }, []);
      return eles;
    });
    /**
    * 将远程结果全部添加到答题maps中
    */
    watch(() => metaQuestions.value ?? [], (newVal) => {
      const res: IQuestionResItem[] = getQuestionResItems(newVal);
      const maps = _.keyBy(res, (item) => {
        return item.model.id.toString();
      });
      console.log('res:', res);
      setAnswerMaps(maps);
    }, { immediate: true });
    /**
     * 展示的结果
     */
    const displayQuestions = computed(() => {
      const res = displays.value.reduce((res: IQuestionResItem[], display) => {
        const model: IQuestionResItem | undefined = answerMaps.value[display];
        if (model) {
          return [...res, model];
        }
        return res;
      }, []);
      return res;
    });
    const progressEle = computed(() => {
      // 必填的总数
      const total = displayQuestions.value.filter(c => c.model.required).length;
      const count = displayQuestions.value.reduce((res: number, item) => {
        if (item.model.required) {
          const { type, value } = item;
          if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
            if (value.length > 0) {
              return res + 1;
            }
          }
          if (type === EQuestionItemType.Text) {
            if (value.length !== 0 && value !== undefined) {
              return res + 1;
            }
          }
        }
        return res;
      }, 0);
      const percentage = (count / total) * 100;
      return <t-progress
        theme='line'
        percentage={percentage}
        strokeWidth='3px'
        label={() => null}
        trackColor='#C2DAFF'
        color='#0A6CFF'
      />;
    });
    const verifyQuestions = () => {
      const errors = verifyResQuestions(displayQuestions.value);
      return errors;
    };
    const submit = () => {
      const errors = verifyQuestions();
      if (errors.length > 0) {
        MessagePlugin.error(errors[0]);
        return;
      }
      router.replace({
        name: 'SurveySuccessView',
      });
    };
    return {
      progressEle,
      metaQuestions,
      answerMaps,
      displayTreeNodes,
      flatDisplayTreeNodes,
      displays,
      displayQuestionEles,
      displayQuestions,
      submit,
    };
  },
  render() {
    console.log('questions:', this.metaQuestions);
    return <div class='question-previewer-panel h-100-p flex-columns'>
      <div class='flex-none'>
        <Row class='h-10px w-100-p ' align='middle' style={{ background: '#F0F6FF' }}>
          <Col span={12} class='pl-24px pr-24px'>
            {this.progressEle}
          </Col>
        </Row>
      </div>
      <div class='flex-1 pl-24px pr-24px pt-16px' style={{ overflowY: 'auto' }}>
        {this.displayQuestionEles}
      </div>
      <div class='flex-none pl-24px pr-24px pt-16px pb-48px'>
        <div
          class='submit'
          onClick={() => {
            this.submit();
          }}
        >
          <Row class='w-100-p h-100-p' justify='center' align='middle'>
            <Col class='c-fff fs-14px'>提交</Col>
          </Row>
        </div>
      </div>
    </div>;
  },
});
